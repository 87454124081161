$gutter_sm: 15px;
$gutter_md: 25px;
$gutter_lg: 40px;
html {
  font-size: 10px;
}
body {
  background: #fff;
  font-family: 'Comic Sans MS';
  font-size: 1.6rem;
  color: #333;
}

/*
Utilities
*/
.invisible {
  opacity: 0;
}
.m_t_0 {
  margin-top: 0;
}
.m_b_0 {
  margin-bottom: 0;
}
.m_b_sm {
  margin-bottom: 0.5rem;
}
.m_b_md {
  margin-bottom: 1rem;
}
.button_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  max-width: 60rem;
}

.text-sm {
  font-size: 1.4rem;
}

/*
Elements
*/
h1 {
  font-weight: normal;
}
img {
  max-width: 100%;
}
input[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
form,
label,
textarea {
  display: block;
  width: 100%;
}
input[type='submit'] {
  font-size: 18px;
  color: #fff;
  background-color: #592466;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 0 15px;
  height: 40px;
  font-family: 'Comic Sans MS';
  letter-spacing: 1px;
  position: relative;
  &:active {
    top: 2px;
    box-shadow: none;
  }
  &:focus {
    outline: none;
  }
}
textarea {
  padding: 1rem;
}

.wrapper {
  max-width: 100rem;
  margin: 0 auto;
  padding: 2rem $gutter_sm 4rem $gutter_sm;
}
.wrapper.use-wrap {
  min-height: 100vh;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 60rem;
}
.setup-wrap {
  section {
    margin-bottom: 3rem;
  }
}

.header {
  background: #607d8b;
  color: #fff;
  position: absolute;
  padding: 1.5rem;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1.5rem;
}

.copied-alert {
  color: #582566;
  margin: 1rem 0 0.5rem 0;
  border: 1px solid #582566;
  display: inline-block;
  padding: 5px 10px;
  background: #fefaff;
}

@media (min-width: 40rem) {
  .wrapper {
    padding-left: $gutter_md;
    padding-right: $gutter_md;
  }
}
@media (min-width: 60rem) {
  .wrapper {
    padding-left: $gutter_lg;
    padding-right: $gutter_lg;
  }
  .setup-wrap {
    section {
      display: grid;
      grid-template-columns: 1fr 3fr;
      border-bottom: 1px solid #ddd;
      margin-bottom: 2rem;
      padding: 2rem;
      grid-gap: 3rem;
    }
  }
}
